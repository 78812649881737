/*! 2025-4-2 20:17:05 */
[data-v-07fbaa3e]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.popover-title[data-v-07fbaa3e]{display:inline-block;width:16px;height:16px;line-height:16px;text-align:center;font-size:13px;margin-left:5px;position:absolute;bottom:10px;right:10px}.popover-title[data-v-07fbaa3e]:hover{cursor:pointer}.green-box[data-v-07fbaa3e]{background:rgba(112,182,3,0.2);color:#70b603}.origin-box[data-v-07fbaa3e]{background:rgba(245,154,35,0.2);color:#f59a23}

[data-v-aff13828]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.h3-wrapper[data-v-aff13828]{display:flex}.h3-wrapper .inspection-result[data-v-aff13828]{flex:1}.page-header[data-v-aff13828]{height:33px}[data-v-aff13828] .page-header .el-form-item{margin-bottom:0}.divider[data-v-aff13828]{height:1px;background-color:#f5f7fa;margin:9px 0 15px 0}.mb30[data-v-aff13828]{margin-bottom:30px}.mt30[data-v-aff13828]{margin-top:30px}.mb20[data-v-aff13828]{margin-bottom:20px}.mt20[data-v-aff13828]{margin-top:20px}.mb15[data-v-aff13828]{margin-bottom:15px}.mt15[data-v-aff13828]{margin-top:15px}.table-container[data-v-aff13828]{padding:20px 15px !important}.operation[data-v-aff13828]{display:flex;justify-content:space-evenly}.operation i[data-v-aff13828]{font-size:20px}.pointer[data-v-aff13828]{cursor:pointer}.error-info[data-v-aff13828]{color:#ff4d4f;font-size:12px;line-height:1;position:relative;padding-top:5px}[data-v-aff13828] .el-input-number--small{width:100px !important}[data-v-aff13828] .el-input-number--small .el-input__inner{padding:0 10px !important}[data-v-aff13828] .el-input-number--small .el-input-number__increase,[data-v-aff13828] .el-input-number--small .el-input-number__decrease{display:none !important}[data-v-aff13828] .el-input-number--small.error .el-input__inner{border-color:#ff4d4f}


.inbound .el-select-dropdown__item {
  width: 200px;
}
.el-tooltip__popper {
  max-width: 400px;
}


/*# sourceMappingURL=chunk-2376e32a.7def7663.css.map*/